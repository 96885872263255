<template>
  <div class="section1">
    <div class="fullscreen bg" v-if="!isMobile">
      <img class="l1" src="./s1/l1.png" alt="光" />
      <img class="l2" src="./s1/l2.png" alt="光" />
      <img class="l3" src="./s1/l3.png" alt="光" />
      <div class="text">
        <img src="./s1/logo.png" alt class="logo" />
        <div class="desc">鋪設通往希望的康莊大道</div>
        <div class="subdesc">擁抱夢想 實現目標</div>
      </div>
      <svg
        version="1.1"
        id="圖層_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1920 1190"
        style="enable-background:new 0 0 1920 1190;"
        xml:space="preserve"
        preserveAspectRatio="xMaxYMax slice"
      >
        <g>
          <g>
            <defs>
              <circle id="SVGID_7_" cx="0" cy="0" r="64">
                <animateMotion
                  dur="27s"
                  path="M1747,2288c-652,0-1180.5-528.5-1180.5-1180.5S1095-73,1747-73"
                  repeatCount="indefinite"
                />
              </circle>
            </defs>
            <clipPath id="SVGID_8_">
              <use xlink:href="#SVGID_7_" style="overflow:visible;" />
            </clipPath>
            <g class="st6">
              <image
                style="overflow:visible;"
                width="1210"
                height="1280"
                xlink:href="./s1/bg2.jpg"
                transform
                class="st8"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <circle id="SVGID_9_" cx="0" cy="0" r="39.5">
                <animateMotion
                  dur="19s"
                  path="M1314.8,2464C570.1,2464-33.5,1860.4-33.5,1115.8S570.1-232.5,1314.8-232.5"
                  repeatCount="indefinite"
                />
              </circle>
            </defs>
            <clipPath id="SVGID_10_">
              <use xlink:href="#SVGID_9_" style="overflow:visible;" />
            </clipPath>
            <g class="st7">
              <image
                style="overflow:visible;"
                width="1210"
                height="1280"
                xlink:href="./s1/bg2.jpg"
                transform
              />
            </g>
          </g>
        </g>
        <circle id="o2" class="st2" cx="0" cy="0" r="70.5">
          <animateMotion
            dur="15s"
            path="M1393.7,2276.4c-664,0-1202.2-538.2-1202.2-1202.2S729.8-128,1393.7-128"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
      <svg
        version="1.1"
        id="圖層_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1920 1121"
        style="enable-background:new 0 0 1920 1121;"
        xml:space="preserve"
        preserveAspectRatio="xMidYMax slice"
      >
        <g>
          <g>
            <defs>
              <path
                id="SVGID_3_"
                d="M2037.8,812.4c-279.6-215.4-775.1-161-985.1,112.7c-183.4,229.2-130.4,570-135.5,834.9h-52.1
				c12.9-393.4-32-539.4,36.8-733.7c176-514.7,883.8-615.5,1253.3-378.5L2037.8,812.4z"
              />
            </defs>
            <clipPath id="SVGID_4_">
              <use xlink:href="#SVGID_3_" style="overflow:visible;" />
            </clipPath>
            <g class="st4">
              <g>
                <defs>
                  <circle id="SVGID_5_" cx="0" cy="0" r="640">
                    <animateMotion
                      dur="3s"
                      path="M798.5,1830c-72-618,284-1054,541-1054"
                      fill="freeze"
                    />
                  </circle>
                </defs>
                <clipPath id="SVGID_6_">
                  <use xlink:href="#SVGID_5_" style="overflow:visible;" />
                </clipPath>
                <g class="st5">
                  <image
                    style="overflow:visible;"
                    width="1210"
                    height="1280"
                    xlink:href="./s1/bg2.jpg"
                    transform="matrix(1 0 0 1 710 516.5)"
                  />
                  <path class="st2" d="M2095,729.3C1730.9,469.6,889.5,588.2,889.5,1314.7v444.8" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <!--  -->
      <svg
        version="1.1"
        id="圖層_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1920 1190"
        style="enable-background:new 0 0 1920 1190;"
        xml:space="preserve"
        preserveAspectRatio="xMaxYMid meet"
      >
        <g>
          <defs>
            <path
              id="SVGID_1_"
              d="M2019.2,409.4c-488.5,59.1-316.3,488.4-122.6,574.4c169.8,74.8,339.8,49.4,333.3,78.5
			c7.5,31.7,32.6,135,10.3,132.4c-203.6,55.6-470.8-30.8-603.1-152.1c-399.4-371-166.9-907.2,357-923L2019.2,409.4z"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_1_" style="overflow:visible;" />
          </clipPath>
          <g class="st0">
            <defs>
              <circle id="o" cx="0" cy="0" r="552.5">
                <animateMotion
                  dur="4s"
                  begin="3s"
                  path="M2484.5,1218c-1376,9-1250-325-551-577"
                  fill="freeze"
                />
              </circle>
            </defs>
            <clipPath id="o_1_">
              <use xlink:href="#o" style="overflow:visible;" />
            </clipPath>
            <g class="st1">
              <image
                style="overflow:visible;"
                width="795"
                height="1280"
                xlink:href="./s1/bg3.jpg"
                transform="matrix(1 0 0 1 1125 0.5)"
              />

              <image
                class="st31"
                style="overflow:visible;"
                width="795"
                height="1280"
                xlink:href="./s1/bg31.png"
                transform="matrix(1 0 0 1 1125 0.5)"
              />

              <image
                class="st3"
                style="overflow:visible;"
                width="795"
                height="1280"
                xlink:href="./s1/bg32.png"
                transform="matrix(1 0 0 1 1125 0.5)"
              />
              <path
                class="st2"
                d="M2249.1,1119.9c-107.2,18.3-265.3-4.1-370.1-45.8c-379.6-151.2-468.8-758.5,134.4-810.7"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="fullscreen bg" v-if="isMobile">
      <img class="l1" src="./s1/mo/l1.jpg" alt="光" />
      <img class="l3" src="./s1/mo/l2.png" alt="光" />
      <div class="text">
        <img src="./s1/logo.png" alt class="logo" />
        <div class="desc">鋪設通往希望的康莊大道 <div>擁抱夢想 實現目標</div></div>
      </div>
      <svg
        version="1.1"
        id="圖層_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1920 1190"
        style="enable-background:new 0 0 1920 1190;"
        xml:space="preserve"
        preserveAspectRatio="xMaxYMax slice"
      >
        <g>
          <g>
            <defs>
              <circle id="SVGID_7_" cx="0" cy="0" r="64">
                <animateMotion
                  dur="27s"
                  path="M1747,2288c-652,0-1180.5-528.5-1180.5-1180.5S1095-73,1747-73"
                  repeatCount="indefinite"
                />
              </circle>
            </defs>
            <clipPath id="SVGID_8_">
              <use xlink:href="#SVGID_7_" style="overflow:visible;" />
            </clipPath>
            <g class="st6">
              <image
                style="overflow:visible;"
                width="2420"
                height="1280"
                xlink:href="./s1/bg2.jpg"
                transform="matrix(1 0 0 1 -260 0.5)"
                class="st8"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <circle id="SVGID_9_" cx="0" cy="0" r="39.5">
                <animateMotion
                  dur="19s"
                  path="M1314.8,2464C570.1,2464-33.5,1860.4-33.5,1115.8S570.1-232.5,1314.8-232.5"
                  repeatCount="indefinite"
                />
              </circle>
            </defs>
            <clipPath id="SVGID_10_">
              <use xlink:href="#SVGID_9_" style="overflow:visible;" />
            </clipPath>
            <g class="st7">
              <image
                style="overflow:visible;"
                width="2420"
                height="1280"
                xlink:href="./s1/bg2.jpg"
                transform="matrix(1 0 0 1 -260 0.5)"
              />
            </g>
          </g>
        </g>
        <circle id="o2" class="st2" cx="0" cy="0" r="70.5">
          <animateMotion
            dur="15s"
            path="M1393.7,2276.4c-664,0-1202.2-538.2-1202.2-1202.2S729.8-128,1393.7-128"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
      <svg
        version="1.1"
        id="圖層_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="516 -1319 750 2500"
        style="enable-background:new 516 -1319 750 2500;"
        xml:space="preserve"
        preserveAspectRatio="xMidYMax slice"
      >
        <g>
          <g>
            <defs>
              <path
                id="SVGID_3_"
                d="M2037.8,812.4c-279.6-215.4-775.1-161-985.1,112.7c-183.4,229.2-130.4,570-135.5,834.9h-52.1
				c12.9-393.4-32-539.4,36.8-733.7c176-514.7,883.8-615.5,1253.3-378.5L2037.8,812.4z"
              />
            </defs>
            <clipPath id="SVGID_4_">
              <use xlink:href="#SVGID_3_" style="overflow:visible;" />
            </clipPath>
            <g class="st4">
              <g>
                <defs>
                  <circle id="SVGID_5_" cx="0" cy="0" r="640">
                    <animateMotion
                      dur="3s"
                      path="M798.5,1830c-72-618,284-1054,541-1054"
                      fill="freeze"
                    />
                  </circle>
                </defs>
                <clipPath id="SVGID_6_">
                  <use xlink:href="#SVGID_5_" style="overflow:visible;" />
                </clipPath>
                <g class="st5">
                  <image
                    style="overflow:visible;"
                    width="1210"
                    height="1280"
                    xlink:href="./s1/bg2.jpg"
                    transform="matrix(1 0 0 1 710 516.5)"
                  />
                  <path class="st2" d="M2095,729.3C1730.9,469.6,889.5,588.2,889.5,1314.7v444.8" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg
        version="1.1"
        id="圖層_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="835 -10 1000 1190"
        style="enable-background:new 835 -10 1000 1190;"
        xml:space="preserve"
        preserveAspectRatio="xMaxYMid meet"
      >
        <g>
          <defs>
            <path
              id="SVGID_1_"
              d="M2019.2,409.4c-488.5,59.1-316.3,488.4-122.6,574.4c169.8,74.8,339.8,49.4,333.3,78.5
			c7.5,31.7,32.6,135,10.3,132.4c-203.6,55.6-470.8-30.8-603.1-152.1c-399.4-371-166.9-907.2,357-923L2019.2,409.4z"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_1_" style="overflow:visible;" />
          </clipPath>
          <g class="st0">
            <defs>
              <circle id="o" cx="0" cy="0" r="552.5">
                <animateMotion
                  dur="4s"
                  begin="3s"
                  path="M2484.5,1218c-1376,9-1250-325-551-577"
                  fill="freeze"
                />
              </circle>
            </defs>
            <clipPath id="o_1_">
              <use xlink:href="#o" style="overflow:visible;" />
            </clipPath>
            <g class="st1">
              <image
                style="overflow:visible;"
                width="795"
                height="1280"
                xlink:href="./s1/bg3.jpg"
                transform="matrix(1 0 0 1 1125 0.5)"
              />

              <image
                class="st31"
                style="overflow:visible;"
                width="795"
                height="1280"
                xlink:href="./s1/bg31.png"
                transform="matrix(1 0 0 1 1125 0.5)"
              />

              <image
                class="st3"
                style="overflow:visible;"
                width="795"
                height="1280"
                xlink:href="./s1/bg32.png"
                transform="matrix(1 0 0 1 1125 0.5)"
              />
              <path
                class="st2"
                d="M2249.1,1119.9c-107.2,18.3-265.3-4.1-370.1-45.8c-379.6-151.2-468.8-758.5,134.4-810.7"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>
<style type="text/css">
.st0 {
  clip-path: url(#SVGID_2_);
}
.st1 {
  clip-path: url(#o_1_);
}
.st2 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
}
.st3 {
  mix-blend-mode: screen;
  animation: svgop 1s 8s forwards; opacity: 0;
}
.st31 {
  animation: svgop 1.2s 8.5s forwards; opacity: 0;
}
.st4 {
  clip-path: url(#SVGID_4_);
}
.st5 {
  clip-path: url(#SVGID_6_);
}
.st6 {
  clip-path: url(#SVGID_8_);
}
.st7 {
  clip-path: url(#SVGID_10_);
}
.st8 {
  opacity: 0.5;
}
@keyframes svgop {
  to { opacity: 1;}
}

</style>
<style lang="scss" scoped>
.section1 {
  position: relative;
  z-index: 2;
  background: #fff;
}
.bg svg,
.bg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bg img {
  object-fit: contain;
  object-position: top right;
}
.l1,
.l2,
.l3{ opacity: 0;}
.l1{animation: op 1s 8s forwards;}
.l2{animation: op 1.2s 8.5s forwards;}
.l3{animation: op 1.5s 9s forwards;}
.l1 {
  mix-blend-mode: color;
}
.l2 {
  mix-blend-mode: overlay;
}
@keyframes op {
  to { opacity: 1;}
}

.bg {
  background-image: url('./s1/s1bg.jpg');
  background-position: center;
  background-size: cover;
  position: relative;

  // .img {
  //   position: absolute;
  //   width: 100vw;
  //   bottom: 0;
  //   left: 0;
  // }

  // .imgM {
  //   position: absolute;
  //   width: 100vw;
  //   bottom: 0;
  //   left: 0;
  //   display: none;
  // }

  .logo {
    width: calc(100vw * (373 / 1920));
    height: auto;
    position: relative;
    margin-bottom: 65px;
    // font-family: 'Barlow Condensed', sans-serif;
    // font-size: 36px;
    // font-weight: bold;
  }

  .text {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: 20%;
    z-index: 3;
  }

  .desc,
  .subdesc {
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .subdesc {
    font-size: 20px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .bg {
    background-image: url('./s1/mo/s1bg.jpg');
    .img {
      display: none;
    }
    .imgM {
      display: block;
    }

    .logo {
      width: calc(100vw * (373 / 750));
      height: auto;
      position: relative;
      margin-bottom: 40px;
    }

    .text {
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);
    }

    .desc {
      writing-mode: vertical-rl;
      text-orientation: upright;
      font-size: 17.5px;
      margin: 0 auto;
      text-align: justify;
      padding: 0 2em 0 0;
      > div {
        font-size: 12px;
        margin-right: 5px;
      }
    }

    .subdesc {
    }
  }
}
</style>

<script>
// @ is an alias to /src
import { isMobile, isTablet } from '@/utils'

export default {
  name: 'section1',

  data() {
    return {
      loading: false,
      isMobile,
      isTablet,
    }
  },

  methods: {},
}
</script>
